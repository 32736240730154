<template>
    <div>
        <CreateCategory />
        <CreateBrand />

        <div class="w-90 center mt4 mini-spacing" style="background-color: #FBFCFE;">
            <div class="flex flex-wrap justify-between pa3" style=" font-size: 18px">
                <div class="font-w2 pb3 pb0-l" style="color: #132c8c;">Raw Material</div>
                <!-- <div class="f5">Transaction ID: QWEEEE-210</div> -->
            </div>

            <form class="entity-formx" ref="formRef" @submit.prevent="onSubmit">
                <!-- Product Name -->
                <div class="pa3">
                    <div class="flex flex-column w-100">
                        <label class="pb2">Name</label>
                        <input class="product-input" type="text" v-model="state.name" placeholder="Raw material name" required />
                    </div>
                </div>
                <!-- QTY Measurement-->
                <div class="flex justify-between pa3">
                    <div class="flex flex-column w-50 pr2-l pr1">
                        <label class="pb2">Quantity in Stock</label>
                        <input class="product-input" type="number" v-model="state.quantity" placeholder="46" required />
                    </div>
                    <div class="flex flex-column w-50 pl2-l pl1">
                        <label class="pb2">Measurement</label>
                        <select v-model="state.measurement" class="product-input" type="text">
                            <option value="">None</option>
                            <option value="Unit">Unit</option>
                            <option value="Piece">Piece</option>
                            <option value="Kg">Kg</option>
                            <option value="Litre">Litre</option>
                            <option value="Dozen">Dozen</option>
                            <option value="Carton">Carton</option>
                            <option value="Pack">Pack</option>
                            <option value="Portion">Portion</option>
                            <option value="Wrap">Wrap</option>
                            <option value="Plate">Plate</option>
                            <option value="Bottle">Bottle</option>
                            <option value="Cup">Cup</option>
                            <option value="Yard">Yard</option>
                            <option value="Pairs">Pairs</option>
                            <option value="Hour">Hour</option>
                            <option value="Day">Day</option>
                            <option value="Week">Week</option>
                            <option value="Month">Month</option>
                        </select>
                    </div>
                </div>

                <div v-if="state.quantity > 0" class="pa3">
                    <select id="accountType" v-model="state.accountType" class="w-100" :required="state.quantity > 0">
                        <option value="" selected>Select account type</option>
                        <option value="Cash">Cash</option>
                        <option
                            v-for="(account, index) in bankAccounts"
                            v-bind:key="index"
                            :value="account?.aliasName || account?.bankName"
                        >
                            {{ account?.aliasName || account?.bankName }}
                        </option>
                    </select>
                    <small class="text-danger"
                        >Please note that if the raw material has existing quantity in stock, this transaction will be created as
                        a Purchase/Buy with the Total Amount (Qty in stock x Average Cost) deducted from the balance in the
                        account selected.</small
                    >
                </div>

                <!-- Upload image -->
                <div class="pa3">
                    <div
                        class="br1 "
                        style="border: 1px solid #767676;"
                        @drop.prevent="handleDragImage"
                        @dragover.prevent
                        @dragenter.prevent
                    >
                        <div class="flex items-center pa3">
                            <div class="pv1 ph1-l ph1" style="border: 2px dotted #767676; width:80px;">
                                <img
                                    :src="state?.image || state?.imageUrl || require('@/assets/images/upload-cloud.svg')"
                                    alt="image"
                                    class="h-90 w-90"
                                    style="object-position: center; object-fit: cover"
                                />
                            </div>
                            <div class="pl4-l pl2 lh-copy">
                                <span class="dn">
                                    <input
                                        ref="imageElRef"
                                        type="file"
                                        name="imageFile"
                                        id="imageFile"
                                        accept="image/*"
                                        @change.prevent="handleSelectImage"
                                    />
                                </span>
                                <span class="font-w1 lh-copy pointer" style="color: #132C8C;" @click.prevent="handleClickUpload"
                                    >Browse files </span
                                >or drag your image PNG,JPG,GIF up to 10MB
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Average cost Selling Price-->
                <div v-if="!state.hasVariant" class="flex ml1 flexrow">
                    <div class="flex">
                        <div class=" pr2-l pr1">
                            <label class="pb2 makeBlock">Bar Code</label>
                            <input class="product-input inputRems" type="text" v-model="state.barcode" placeholder="Barcode" />
                        </div>
                        <div class="pl2-l pl1 mr3">
                            <label class="pb2 makeBlock">SKU</label>
                            <input class="product-input inputRems" type="text" v-model="state.sku" placeholder="SKU" />
                        </div>
                    </div>
                    <div class="flex">
                        <div class="pr2-l pr1">
                            <label class="pb2 makeBlock">Average Cost</label>
                            <input
                                class="product-input inputRems"
                                type="number"
                                v-model="state.buyingPrice"
                                placeholder="Buying price"
                                required
                            />
                        </div>
                        <div class=" pl2-l pl1">
                            <label class="pb2 makeBlock">Selling Price</label>
                            <input
                                class="product-input inputRems"
                                type="number"
                                v-model="state.sellingPrice"
                                placeholder="Selling price"
                            />
                        </div>
                    </div>
                </div>

                <!-- Average cost Selling Price-->
                <div v-if="!state.hasVariant" class="flex flex-wrap justify-between pa3">
                    <div class="flex flex-column w-50-l w-50 pr2 pr1-l">
                        <label class="pb2">Size</label>
                        <input class="product-input" type="text" v-model="state.size" placeholder="Size" />
                    </div>
                    <div class="flex flex-column w-50-l w-50 pl2 pl1-l">
                        <label class="pb2">Color</label>
                        <input class="product-input" type="text" v-model="state.color" placeholder="Color" />
                    </div>
                </div>

                <!-- categories/brand -->
                <div class="flex pt2">
                    <div class="w-50 pr2">
                        <div class="flex flex-wrap items-center" style="gap: 0.5rem">
                            <div class="pv2">
                                <label for="category">Category</label>
                            </div>
                            <router-link @click.prevent="onShowCreateCategory(true)" to="#" class="pv2 active bold"
                                >+ Add Category</router-link
                            >
                        </div>

                        <select name="categories" v-model="state.category" class="w-100" id="category">
                            <option value="" selected>Please choose a category</option>
                            <option v-for="category of categories" v-bind:key="category._id" :value="category">{{
                                category.name
                            }}</option>
                        </select>
                    </div>
                    <div class="w-50 pl2">
                        <div class="flex flex-wrap items-center" style="gap: 0.5rem">
                            <div class="pv2"><label for="brands">Brand</label></div>
                            <router-link @click.prevent="onShowCreateBrand(true)" to="#" class="pv2 active bold">
                                + Add New Brand</router-link
                            >
                        </div>

                        <select name="brands" v-model="state.brand" class="w-100" id="brands">
                            <option value="" selected>Please choose a brand</option>
                            <option v-for="brand of brands" v-bind:key="brand._id" :value="brand">{{ brand.name }}</option>
                        </select>
                    </div>
                </div>

                <!-- Inputs Bar code , SKU, Name ,Qty , Measurement and Co -->

                <!-- note -->
                <div class=" flex flex-column pa3">
                    <label class="pb2">Description</label>
                    <textarea v-model="state.description" class="pa3" placeholder="Enter Note" cols="30" rows="2"></textarea>
                </div>

                <!-- creat transaction & cancel Transactions button -->
                <div class="flex justify-between">
                    <div>
                        <router-link :to="{ name: 'RawMaterial' }">&larr; Back</router-link>
                    </div>
                    <div class="pa3 flex">
                        <router-link :to="{ name: 'RawMaterial' }">
                            <button class="btn2">Cancel</button>
                        </router-link>
                        <button class="ml2" type="submit" :disabled="state.disableSubmitButton">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import ShortUniqueId from 'short-unique-id'
import AppWrapper from '@/layout/AppWrapper'
import CreateBrand from '@/components/CreateBrand'
import CreateCategory from '@/components/CreateCategory'

export default {
    name: 'CreateRawMaterial',
    components: { AppWrapper, CreateCategory, CreateBrand },

    setup() {
        const store = useStore()
        const router = useRouter()
        const imageElRef = ref(null)
        const brands = computed(() => store?.state.Inventory.brands)
        const categories = computed(() => store?.state.Inventory.categories)
        const category = computed(() => store?.state.Inventory.category)
        const brand = computed(() => store?.state.Inventory.brand)
        const createProductNotifier = computed(() => store.state.Inventory?.createProductNotifier)
        const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)

        const state = reactive({
            name: '',
            type: 'RAW_MATERIAL',
            measurement: 'Unit',
            sku: '',
            barcode: '',
            description: '',
            quantity: 0,
            accountType: '',
            category: '',
            categoryName: '',
            brand: '',
            brandName: '',
            size: '',
            color: '',
            buyingPrice: 0,
            sellingPrice: 0,
            stockLevel: 0,
            image: '',
            imageUrl: '',
            imageFile: '',
            disableSubmitButton: false
        })

        const handleClickUpload = () => {
            // eslint-disable-next-line
            imageElRef?.value?.click()
        }

        const handleSelectImage = e => {
            if (e?.target?.files?.length) {
                const file = e.target.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.target.files[0]
                const reader = new FileReader()
                reader.onload = e => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const handleDragImage = e => {
            if (e?.dataTransfer?.files?.length) {
                const file = e.dataTransfer.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.dataTransfer.files[0]
                const reader = new FileReader()
                reader.onload = e => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const onShowCreateBrand = (payload = true) => {
            store.dispatch('Inventory/showCreateBrand', payload)
        }
        const onShowCreateCategory = (payload = true) => {
            store.dispatch('Inventory/showCreateCategory', payload)
        }

        const createTransaction = quantity => {
            if (state.buyingPrice) {
                const price = parseFloat(state.buyingPrice)
                const qty = parseFloat(quantity)
                const amount = price * qty
                const name = state.name
                const prodTx = {
                    type: 'purchases',
                    date: new Date(),
                    reference: new ShortUniqueId({ length: 6 }).randomUUID(),
                    products: [{ name, product: createProductNotifier?.value?._id, price, quantity: qty }],
                    amount: parseFloat(amount)
                }

                store.dispatch('Transaction/createNewTransaction', prodTx)
            }
        }

        const onSubmit = () => {
            if (state.buyingPrice === '') {
                state.disableSubmitButton = false
                return store.dispatch('Alert/setAlert', { message: 'Please enter your buying price', status: false })
            }

            if (state.sellingPrice === '') {
                state.disableSubmitButton = false
                return store.dispatch('Alert/setAlert', { message: 'Please enter your selling price', status: false })
            }

            state.disableSubmitButton = true
            if (state.category) {
                state.categoryName = state.category?.name
                state.category = state.category?._id
            }

            if (state.brand) {
                state.brandName = state.brand?.name
                state.brand = state.brand?._id
            }

            if (state.buyingPrice) {
                parseFloat(state.buyingPrice)
            }

            if (state.sellingPrice) {
                parseFloat(state.sellingPrice)
            }

            const { disableSubmitButton, ...data } = state

            const formData = new FormData()

            const dataKeys = Object.keys(data)

            dataKeys.forEach(dataKey => {
                if (data[dataKey]) formData.append(dataKey, data[dataKey])
            })

            store
                .dispatch('Inventory/createProduct', formData)
                .then(resp => {
                    if (state.quantity) {
                        createTransaction(state.quantity)
                    }

                    if (resp.success) {
                        router.replace({ name: 'RawMaterial' })
                    }

                    state.disableSubmitButton = false
                })
                .catch(error => {
                    state.disableSubmitButton = false
                })
        }

        onMounted(() => {
            store.dispatch('Production/getSku', 'RAW_MATERIAL').then(data => {
                state.sku = data
            })
            store.dispatch('Settings/getBankAccounts')
        })

        return {
            state,
            brands,
            categories,
            imageElRef,
            bankAccounts,
            handleClickUpload,
            handleDragImage,
            handleSelectImage,
            onShowCreateBrand,
            onShowCreateCategory,
            onSubmit
        }
    }
}
</script>

<style scoped>
.makeBlock {
    display: block;
}

.inputRems {
    width: 23rem;
}

@media screen and (max-width: 767px) {
    .inputRems {
        width: 12rem;
    }

    .flexrow {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 999px) {
    .inputRems {
        width: 12rem;
    }

    /* .flexrow {
        display: flex;
        flex-direction: column;
    } */
}

@media (min-width: 800px) and (max-width: 1024px) {
    .inputRems {
        width: 17.3rem;
    }
}
</style>
